:root{
  --backgroundColorBox: rgba(138, 137, 137, 0.082);
  --textSize:14px;
  --red-color:#FA3A49;
  --coffe-color:#3C1D2B;
}

/* Add Address View */
#BtnAddAlias{
  margin-left: 15%;
}

#titleFinalForm{
  margin-left: 10px;
  margin-top: 35px;
}
#inputAddress{
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -15px;
}
#addressTable{
  padding: 15px;
  width: 100%;
  margin-top: 12px;
  background: var(--backgroundColorBox);
  border-radius: 1%;
  border: 1px rgba(0, 0, 0, 0.068) solid;
}
#btnBarTop{
  height: 40px;
  margin-right: 20px;
}

/* AddressBook View */
#containerAddressForm{
  padding-top: 5rem;
}

#addressFormDiv{
  width: 100%;
  justify-content: center;
}
#halfInputDiv{
  justify-content: center;
}
#inputAddressForm{
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
#cardLeft{
  width: 550px;
  min-width: 350px;
  background: var(--backgroundColorBox);
}
#halfInput{
  width: 255px;
  margin-top: 25px;
}
#halfInput::-webkit-inner-spin-button, 
#halfInput::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
#labelInputLeft{
  position: absolute;
  left: 22px;
}
#labelInputRight{
  position: absolute;
  left: 277px;
}


/* Calculator View */

#cardGroupCalculator{
  width: 100%;
}

#indications{
  margin-top:10px;
  margin-bottom:50px;
  font-size: 17px;
}

#cardCalculator{
width: 350px;
min-width: 350px;
background: var(--backgroundColorBox);
}
#inputCalculator{
  margin-top: 10px;
}

#miniInputDiv{
  margin-top: 15px;
  justify-content: center;
}

#miniInput{
  width: 140px;
  margin: 2px;
}
#miniInput::-webkit-inner-spin-button, 
#miniInput::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

#btnCalculator{
  margin-top: 25px;
  background: var(--coffe-color);
  color: aliceblue;
}

#cardCalculator .header{
 margin: 10px;
}

#resultDiv{
  margin-top: 15px;
}

#resultTag{
font-weight: normal;
font-size: var(--textSize);
padding-left: 2%;
}
#resultText{  
position: absolute;
right: 15%;
font-weight: normal;
font-size: var(--textSize);
}
#resultTag2{
font-size: var(--textSize);
padding-left: 3%;
}
#resultText2{
  
position: absolute;
right: 15%;
font-size: var(--textSize);
}
#iconQuestion{
  position: absolute;
  margin-top: 5px;
  right: 40px;
}
#headerTable{
  margin-bottom: 25px;
}
#servicesTable{
  padding: 15px;
  width: 72%;
  margin-top: 12px;
  margin-left: 15px;
  background: var(--backgroundColorBox);
  border-radius: 1%;
  border: 1px rgba(0, 0, 0, 0.068) solid;
}

#divTable{
  width: 100%;
}

#logoCompany{
  margin: auto;
}

#btnGroupDiv{
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  background: var(--red-color);
}

#centerBtnActive{
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  margin: 1px;
  outline: none;
  color: white;
  background: var(--red-color);
}