@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@700&display=swap');

:root {
  --globalMarginLeft: 23%;
  --marginYOferts: 10px;
  --fontTitle: "GowunBatangBold";
  --fontTitle2: "GowunBatangRegular";
  --fontText1: "MuliRegular";
  --fontText2: "MuliExtraLight";
  --menuMapDoor: 60px;

}

#btnModal {
  background: #F74550;
  width: 100%;
  height: 44px;
  border-radius: 35px;
  color: #F8F8F8;
  transition: 400ms;
  margin-top: 20px;
  justify-self: left;
}



#container {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
#container::-webkit-scrollbar {
  display: none;
}

#segmentHome {
  width: 100%;
}

#leftColumn {
  width: 50%;
  height: 900px;
  overflow: hidden;
  background: #00342A;
  justify-content: center;
  display: grid;
}

/*---------- Temporl Module---------- */
#headerFormServiceTemporalModule {
  color: #F8F8F8;
  margin-bottom: 30px;
  font-size: 20px;
  font-family: var(--fontTitle);
}

#temporalModule {
  display: grid;
  justify-self: center;
  justify-content: center;
  max-width: 505px;
  margin-left: 40px;
}

#temporalModuleInput {
  width: 472px;
  height: 49px;
  border-radius: 35px;
  max-width: 350px;
}

#btnServicesTemporalModule {
  background: #F74550;
  width: 128px;
  height: 44px;
  border-radius: 35px;
  color: #F8F8F8;
  transition: 400ms;
  margin-left: 10px;
}

#btnServicesTemporalModule:hover {
  transform: scale(0.97);
}

/*----------------------------*/
#miniLogo {
  margin-top: 35px;
  margin-left: var(--globalMarginLeft);
  width: 176px;
  height: 40px;
}

#sloganBox {
  width: 67%;
  margin-top: -20px;
  margin-left: 23%;
}

#slogan1 {
  color: aliceblue;
  font-family: var(--fontTitle);
  font-size: 46px;
}

#slogan2 {
  color: aliceblue;
  font-family: var(--fontTitle2);
  line-height: 47px;
  font-size: 6ch;
  text-align: left;
}

#btnShowBranch {
  background: #F74550;
  color: #F8F8F8;
  margin-top: -206px;
  align-self: center;
  justify-self: center;
  border-radius: 40px;
  transition: 500ms;
}

#btnShowBranch:hover {
  background: #91282f;
}

#divServices {
  background: #F8F8F8;
  width: 480px;
  height: 150px;
  border-radius: 18px;
  margin-top: 80px;
  margin-left: var(--globalMarginLeft);
}

#cardContentService {
  width: 226px;
  margin-left: 4px;
  z-index: 1;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#cardServiceActive {
  background: #F74550;
  width: 160px;
  height: 170px;
  margin-top: -10px;
  border-radius: 18px;
  position: absolute;
  transition: 1s;
}


#iconService {
  width: 80px;
  height: 102px;
  cursor: pointer;
}


#textService {
  font-size: 23px;
  font-family: var(--fontTitle);
  text-align: center;
  position: absolute;
  margin-top: 120px;
}

#formServices {
  width: 681px;
  height: 192px;
  margin-top: 50px;
  margin-left: var(--globalMarginLeft);
  transition: 1s;
  animation: fadeIn 2s;
}

#headerFormService {
  color: #F8F8F8;
  margin-top: 10px;
  margin-left: 20px;
  font-family: var(--fontTitle);
}

#helpUser {
  color: #F8F8F8;
  margin-top: 10px;
  margin-left: 20px;
}

#inputGroup {
  padding: 15px;
  margin: 5px;
}

#inputService {
  width: 187px;
  height: 49px;
  border-radius: 35px;
  margin-right: 10px;
}

#inputServiceLarge {
  width: 472px;
  height: 49px;
  border-radius: 35px;
}

#inputServiceLarge::placeholder,
#inputService::placeholder {
  color: rgb(0, 0, 0);
  font-size: 14px;
}

#iconForm {
  margin-top: 15px;
  margin-right: 9px;
  color: #F8F8F8;
}

#btnServices {
  background: #F74550;
  border-radius: 35px;
  color: #F8F8F8;
  transition: 400ms;
  margin-left: 10px;
}

#btnServices:hover {
  transform: scale(0.97);
}

/* Right Column */

#rightColumn {
  width: 50%;
  overflow: hidden;
  background: #ffffff;
}

#menuDiv {
  position: absolute;
  margin-top: 20px;
  right: 150px;
}

#MenuItem {
  border-radius: 35px;
  transition: 600ms;
  cursor: pointer;
}

#MenuItemActive {
  border-radius: 35px;
  transition: 600ms;
  cursor: pointer;
  background: #F74550;
  color: #F8F8F8;
}

#headerOfert {
  margin-top: 40px;
  margin-left: 65px;
  font-size: 24px;
  font-family: var(--fontTitle);
}


#itemOfert {
  background: #FFFFFF;
  border: 1px solid #FFE6E6;
  width: 315px;
  height: 50px;
  padding: 10px;
  border-radius: 34px;
  margin-top: var(--marginYOferts);
  margin-bottom: var(--marginYOferts);
  margin-left: 70px;
  transition: 1s;
  cursor: pointer;
}

#itemOfert2 {
  background: #FFFFFF;
  border: 1px solid #AAE5D9;
  width: 315px;
  padding: 10px;
  border-radius: 34px;
  margin-top: var(--marginYOferts);
  margin-bottom: var(--marginYOferts);
  margin-left: 70px;
  transition: 1s;
  cursor: pointer;
}

#itemOfert3 {
  background: #FFFFFF;
  border: 1px solid #C6E1F7;
  width: 315px;
  height: 50px;
  padding: 10px;
  border-radius: 34px;
  margin-top: var(--marginYOferts);
  margin-bottom: var(--marginYOferts);
  margin-left: 70px;
  transition: 1s;
  cursor: pointer;
}


#iconOfert {
  width: 30px;
  height: 30px;
  pointer-events: none;
}

#textOfert {
  margin-top: 5px;
  margin-left: 10px;
  pointer-events: none;
}

#person1 {
  position: absolute;
  left: 40px;
  bottom: 0;
}

/* section 2 [Carousel] */

#section2 {
  justify-content: center;
  display: grid;
  padding-bottom: 20px;
}

#headerBarCarousel {
  margin-top: 20px;
  padding: 10px;
}

#carouselTitle {
  position: absolute;
  font-family: var(--fontTitle);
  font-size: 32px;
}

#carousel {
  width: 100%;
  height: 230px;
  overflow-y: hidden;
  margin: 5px;
  align-self: center;
  justify-self: center;
  justify-content: center;
}

#containerBtn {
  width: 100%;
  margin: 5px;
  padding: 10px;
  overflow: hidden;
}

#btnCarusel {
  border-radius: 50%;
  margin: 2px;
  border: 1px rgba(0, 0, 0, 0.445) solid;
}

#cardBanner {
  width: 350px;
  margin: 5px;
  transition: 600ms;
  cursor: pointer;
  border-radius: 20px;
}

#cardBanner:hover {
  transform: scale(0.98);
}

#containerTextBanner{
  position: absolute;
  margin-top: -11rem;
  padding-left: 25px;
  padding-top: 19px;
  width: 200px;
  height: 140px;
  overflow: hidden;
}
#titleImgCarousel{
  font-family: var(--fontTitle);
  font-size: 21px;
  color: #c0e8f0;
  word-break: break-all;
  overflow: hidden;
}
#descriptionImgCarousel{
  font-family: var(--fontText1);
  font-size: 15px;
  word-break:normal;
  overflow: hidden;
  margin-top: 10px;
  width: 170px;
  word-spacing: 0.5px;
  letter-spacing: 0px;
  white-space:pre-wrap;
  text-align: justify;
  color: #f4f8f8;
}


/* Section 3 [how does it service] */


#section3 {
  display: grid;
  margin-top: -20px;
}

#textContainer {
  justify-content: center;
  text-align: center;
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
}

#howDoesItTextSection3 {
  font-family: var(--fontText1);
  font-size: 14px;
}

#titleSection3 {
  max-width: 590px;
  margin-top: -2px;
  line-height: 47px;
  font-family: var(--fontTitle);
  justify-self: center;
  font-size: 47px;
}

#textSection3 {
  width: 564px;
  justify-self: center;
  font-family: var(--fontText1);
  font-size: 18px;
}

#divCardFunctions {
  justify-self: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

#cardFunctions1 {
  width: 480px;
  height: 540px;
  justify-content: center;
  align-items: center;
  background: #0F0F0E;
}

#cardFunctions2 {
  width: 480px;
  height: 540px;
  justify-content: center;
  align-items: center;
  background: #501244;
}

#cardFunctions3 {
  width: 480px;
  height: 540px;
  justify-content: center;
  align-items: center;
  background: #F74550;
}

#cardFunctions1 .image {
  position: absolute;
  top: 125px;
  width: 150px;
  height: 150px;
  transition: 700ms;
}

#cardFunctions2 .image {
  position: absolute;
  top: 160px;
  width: 150px;
  height: 150px;
  transition: 700ms;
}

#cardFunctions3 .image {
  position: absolute;
  top: 100px;
  width: 140px;
  height: 140px;
  transition: 700ms;
}

#cardFunctions1 .image:hover,
#cardFunctions2 .image:hover,
#cardFunctions3 .image:hover {
  transform: scale(1.2);
}

#cardFunctions1 .header,
#cardFunctions2 .header,
#cardFunctions3 .header {
  color: #ffffff;
  font-family: var(--fontTitle);
  text-align: center;
  margin-top: 25rem;
}

#cardFunctions1 .description,
#cardFunctions2 .description,
#cardFunctions3 .description {
  color: #ffffff;
  font-family: var(--fontTitle2);
  text-align: center;
  width: 250px;
}


#btnServices2 {
  background: #F74550;
  color: #F8F8F8;
  font-family: var(--fontTitle);
  width: 170px;
  height: 44px;
  border-radius: 35px;
  transition: 400ms;
  justify-self: center;
}

#btnServices2:hover {
  transform: scale(0.97);
}

/* Section 4 [Brands and benefits] */
#section4 {
  margin-top: 150px;
  margin-bottom: 40px;
  justify-content: center;
    display: flex;
}

#howDoesItTextSection4 {
  font-family: var(--fontText1);
  font-size: 14px;
}

#titleSection4 {
  max-width: 380px;
  margin-top: -2px;
  line-height: 47px;
  font-family: var(--fontTitle);
  justify-self: center;
  font-size: 41px;
}

#benefitsText {
  font-family: var(--fontText1);
  font-size: 16px;
  word-break: keep-all;
  width: 400px;
  transition: 1s;
  animation: fadeIn 3s;
  margin: 5px;
}

#textSection4 {
  justify-self: center;
  font-family: var(--fontText1);
  font-size: 18px;
}

#TextBottonSection4 {
  font-family: var(--fontText2);
  font-size: 14px;
}


#leftColumnSection4 {
  padding-left: 1%;

}

#rightColumnSection4 {
  width: 550px;
  height: 450px;
  margin-left: 23rem;
}

#listBenefitsContainer {
  margin-top: 12px;
  width: 600px;
  height: 220px;
}

#iconList {
  width: 20.43px;
  height: 20.43px;
}

#brandLogo {
  width: 30px;
  margin: 20px;
}

#brandLogo2 {
  width: 110px;
  margin: 10px;
}

#backgroundPerson2 {
  width: 550px;
  height: 450px;
  background: url(../../assets/bgPerson2.png);
  background-size: cover;
  background-repeat: no-repeat;
}

#contentPerson2 {
  background: #f5e2d2;
  position: absolute;
  width: 318px;
  height: 426px;
  overflow: hidden;
  margin-top: -20px;
  margin-left: 120px;
  border-radius: 2%;
}

#redPlane {
  position: absolute;
  z-index: 1;
  margin-top: 100px;
  pointer-events: none;
}

#person2 {
  margin-top: 24px;
  width: 300px;
  transition: 2s;
}

#person2:hover {
  transform: scale(1.2);
}

/* Section 5 [Form] */

#section5 {
  margin-top: 70px;
  margin-bottom: 40px;
  background: #490A3C;
  color: #F8F8F8;
  padding: 80px;
  justify-content: center;
    display: flex;

}

#leftColumnSection5 {
  padding-left: 1%;
}

#rightColumnSection5 {  
  padding-top: 5em;
  margin-left: 35%;
}

#howDoesItTextSection5 {
  font-family: var(--fontText1);
  font-size: 14px;
}

#titleSection5 {
  max-width: 430px;
  margin-top: 10px;
  margin-bottom: 30px;
  line-height: 47px;
  font-family: var(--fontTitle);
  justify-self: center;
  color: #EDEDED;
  font-size: 47px;
}

#halfInputForm {
  margin: 5px;
  height: 59px;
  border: 1px rgba(0, 0, 0, 0.171) solid;
  background: rgba(0, 0, 0, 0.041);
}

#halfInputFormDorpDown {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 5px;
  width: 223px;
  height: 59px;
  border: 1px rgba(0, 0, 0, 0.171) solid;
  background: rgba(0, 0, 0, 0.041);
}

#completeInputForm {
  width: 449px;
  border: 1px rgba(0, 0, 0, 0.171) solid;
  background: rgba(0, 0, 0, 0.041);
  padding: 10px;
  resize: none;
  outline: none;
}

#textSection5 {
  width: 413px;
  font-size: 16px;
  font-family: var(--fontText1);
  text-align: justify;
}

#checkBoxGroup {
  margin-left: 2px;
}

#checkBoxText {
  margin-left: 10px;
  width: 141px;
  font-family: var(--fontText2);
  font-size: 12px;
}

#btnSend {
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  border-top-left-radius: 35px;
  transition: 400ms;
  margin-left: 100px;
  width: 173px;
  height: 59px;
}

#btnSend:hover {
  transform: scale(0.98);
}

/* Section 6 [Map]*/

#section6 {
  margin-top: -40px;
  margin-bottom: -80px;
  color: #F8F8F8;
  padding: 80px; 
  display: grid;
  justify-content: center;

}


#cardGroupMaps {
  margin-top: 20px;
}

#titleSection6 {
  max-width: 520px;
  margin-bottom: 20px;
  font-family: var(--fontTitle);
  font-size: 42px;
}

#leftColumnMap {
  height: 600px;
  display: grid;
  justify-content: left;
}

#rightColumnMap {
  display: grid;
  height: 525px;
  margin-left: 0%;
  justify-content: right;
  color: #0F0F0E;
}

#cardContentAccordion {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 400px;
}

#acordionLocation {
  max-width: 100%;
  margin-top: 0px;
  margin-left: 200px;
  border-bottom: 1px solid #EDEDED;
}

#gridContentLocation {
  max-width: 700px;
  padding-left: 80px;
  margin-bottom: 10px;
}

#cardLocation {
  width: 650px;
  max-width: 700px;
  padding: 10px;
  margin-left: 10px;
}


#contentHeaderDropdown1 {
  margin-top: 5px;
  width: 290px;
}

#contentHeaderDropdown2 {
  margin-left: 400px;
  margin-top: -22px;
  width: 250px;
}

#dropUbicationHeader {
  margin-top: 10px;
  padding-top: 2px;
  margin-left: 10px;
  font-size: 13px;
}

#dropUbicationHeader2 {
  text-align: left;
  width: 180px;
  font-size: 13px;
}
#subheader2Ubication{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #F74550;
  text-align: left;
  font-size: 11px;
}

#inputGroupMap {
  z-index: 1;
  justify-self: center;
  background: rgb(255, 255, 255);
  padding: 15px;
  margin: 5px;
}

#inputServiceLargeMap {
  width: 422px;
  margin-left: 5px;
  height: 44px;
  border-radius: 35px;
}

#inputServiceLargeMap::placeholder,
#inputServiceMap::placeholder {
  color: rgb(0, 0, 0);
  font-size: 14px;
}

#btnServicesMap {
  background: #F74550;
  border-radius: 35px;
  width: 138px;
  height: 44px;
  color: #F8F8F8;
  transition: 400ms;
  margin-left: 10px;
}

#btnServicesMap:hover {
  transform: scale(0.97);
}

#textRevealAcordion {
  font-size: 13px;
  width: 170px;
  margin-left: -15px;
  text-align: justify;
  font-family: var(--fontText1);
}

#textRevealAcordion2 {
  font-size: 13px;
  width: 180px;
  text-align: justify;
  margin-left: 50px;
  font-family: var(--fontText1);
}

/* Menu maps */
#menuSection6 {
  height: var(--menuMapDoor);
  /*90-380*/
  overflow: hidden;
  transition: 900ms;
  margin-bottom: 30px;
  display: grid;
  justify-content: left;
}

#menuItemMap {
  width: 100%;
  text-align: center;
}

#btnMenuMap {
  border-bottom: 1px solid #EDEDED;
  color: #000;
  padding-bottom: 15px;
}

#btnMenuMap:hover {
  color: #F74550;
}

#map {
  margin-top: 30px;
  max-width: 550px;
  max-height:450px ;
  border-radius: 10px;
}

#menuContainerSection6 {
  margin-top: 25px;
  margin-bottom: 50px;
  display: grid;
  justify-content: end;
}

#btnMenuSection6 {
  background: transparent;
  border-right: 2.5px solid #EDEDED;
}

#btnMenuSection6Active {
  background: transparent;
  border-right: 2.5px solid #EDEDED;
  color: #F74550;
}


/* Section 7 [Footer] */

#section7 {
  margin-top: 70px;
  margin-bottom: 40px;
  background: #0F0F0E;
  color: #F8F8F8;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: 80px;

}

#titleFooter {
  color: #FFFFFF;
}


#miniLogoFooter {
  width: 176px;
  height: 38px;
  margin-left: -20px;
}


/* MEDIA QUERY */

@media (min-width: 2500px) {
  body{
    display: grid;
    justify-content: center;
  }
  #section5 {
    margin-top: 70px;
    margin-bottom: 40px;
    background: #490A3C;
    color: #F8F8F8;
    padding: 80px;
  
  }
  #leftColumnSection5 {
    padding-left: 0;
  }
  #rightColumnSection5 {  
  padding-top: 5em;
  margin-left: 36rem;
}
}

/* Iphone X */


@media (max-width: 700px) {
  body{
    display: grid;
    justify-content: center;
  }

  #container {
    max-width: 411px;
    overflow: hidden;
  }

  #miniLogo {
    margin-top: 5px;
    margin-left: 10px;
    width: 130px;
    height: 31px;
  }

  #temporalModule {
    display: grid;
    justify-self: center;
    justify-content: center;
    max-width: 505px;
    margin-left: 0px;
  }

  /* section 1*/

  #headerOfert,
  #cardOfert {
    justify-self: center;
    margin-left: -80px;
  }

  #leftColumn {
    width: 100%;
    height: 600px;
    overflow: hidden;
    background: #00342A;
  }
 

  #rightColumn {
    width: 100%;
    height: 400px;
    overflow: hidden;
    background: #ffffff;
    display: grid;
    justify-content: center;
  }

  #person1 {
    width: 66%;
    margin-left: 50px;
    justify-self: center;
    bottom: 0;
  }

  #cardOfert {
    margin-top: -160px;
    height: 125px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  #cardOfert::-webkit-scrollbar {
    display: none;
  }

  /* section 2 [Carousel] */
  #section2 {
    justify-content: center;
    display: grid;
    padding-bottom: 0px;
  }

  #carouselTitle {
    position: absolute;
    font-family: var(--fontTitle);
    margin-top: 5px;
    font-size: 22px;
  }

  #carousel {
    width: 100%;
    height: 220px;
    overflow-y: hidden;
    margin: 5px;
    align-self: center;
    justify-self: center;
    justify-content: center;
  }

  /* Section 3 [how does it service] */
  #section4 {
    margin-top: 90px;
    margin-bottom: 40px;
    justify-content: center;
    display: flex;
  }

  #textContainer {
    justify-content: center;
    text-align: center;
    display: grid;
    max-width: 380px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #titleSection3 {
    max-width: 300px;
    margin-top: -2px;
    font-family: var(--fontTitle);
    justify-self: left;
    font-size: 32px;
  }

  #textSection3 {
    width: 300px;
    justify-self: center;
  }

  #cardFunctions1 {
    width: 330px;
    height: 540px;
    justify-content: center;
    align-items: center;
    background: #0F0F0E;
  }

  #cardFunctions2 {
    width: 330px;
    height: 540px;
    justify-content: center;
    align-items: center;
    background: #501244;
  }

  #cardFunctions3 {
    width: 330px;
    height: 540px;
    justify-content: center;
    align-items: center;
    background: #F74550;
  }

  /* Section 4 [Brands and benefits] */


  #backgroundPerson2 {
    position: absolute;
    right: 0;
    width: 350px;
    height: 350px;
    background: url(../../assets/bgPerson2.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  #contentPerson2 {
    background: #f5e2d2;
    position: absolute;
    width: 200px;
    height: 300px;
    overflow: hidden;
    margin-top: -20px;
    margin-left: 70px;
    border-radius: 2%;
  }

  #redPlane {
    position: absolute;
    z-index: 1;
    margin-top: 100px;
    pointer-events: none;
  }

  #person2 {
    margin-top: 32px;
    width: 300px;
    transition: 2s;
  }


  /* Section 5 [Form] */
  #section5 {
    margin-top: 70px;
    margin-bottom: 40px;
    margin-left: -25px;
    background: #490A3C;
    color: #F8F8F8;
    justify-content: center;
    display: flex;
    padding: 80px;

  }

  #howDoesItTextSection5 {
    font-family: var(--fontText1);
    font-size: 14px;
    text-align: center;
  }

  #titleSection5 {
    max-width: 300px;
    margin-top: 8px;
    line-height: 42px;
    font-family: var(--fontTitle);
    justify-self: center;
    text-align: center;
    font-size: 32px;
    color: #F8F8F8;
  }

  #textSection5 {
    width: 300px;
    font-size: 12px;
  }

  #halfInputForm {
    margin: 5px;
    border: 1px rgba(0, 0, 0, 0.171) solid;
    background: rgba(0, 0, 0, 0.041);
  }

  #completeInputForm {
    width: 300px;
    border: 1px rgba(0, 0, 0, 0.171) solid;
    background: rgba(0, 0, 0, 0.041);
    padding: 10px;
    resize: none;
    outline: none;
  }


  #checkBoxText {
    margin-left: 10px;
    width: 260px;
    font-family: var(--fontText2);
    font-size: 12px;
  }

  #btnSend {
    background: #F74550;
    border-radius: 35px;
    color: #F8F8F8;
    transition: 400ms;
    margin-top: 40px;
    margin-left: 10px;
    width: 90%;
    height: 50px;
  }


  /* Section 6 [Map]*/

  #section6 {
    margin-top: -40px;
    margin-bottom: -80px;
    color: #F8F8F8;
    padding: 80px;
    display: grid;
    justify-content: center;

  }

  #leftColumnMap {
    width: 100%;
    display: grid;
    justify-content: center;
    max-height: 300px;
  }

  #rightColumnMap {
    width: 100%;
    display: grid;
    justify-content: right;
    margin-left: 0px;
  }
  #cardContentAccordion {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 400px;
  }

  #map {
    max-width: 390px;
    border-radius: 10px;
  }

  #acordionLocation {
    margin-top: 20px;
    margin-left: 30px;

  }

  #titleSection6 {
    max-width: 520px;
    margin-top: 15px;
    margin-left: -35px;
    font-family: var(--fontTitle);
    justify-self: center;
    font-size: 25px;
  }

  #menuIconMap {
    position: absolute;
    right: 20px;
    width: 45px;
    height: 35px;
    background: #f7455100;
    border: solid 2px #EDEDED;
  }
  


  #gridContentLocation {
    max-width: 600px;
    margin-left: -105px;
  }

  #contentHeaderDropdown1 {
    margin-top: -30px;
    margin-left: -30px;
    width: 270px;
  }

  #contentHeaderDropdown2 {
    margin-left: -38px;
    margin-top: -32px;
    width: 250px;
  }

  #dropUbicationHeader {
    font-size: 12px;
    margin-top: 29px;
    margin-right: 10px;
    margin-left: 3px;
  }

  #dropUbicationHeader2 {
    width: 70px;
    font-size: 12px;
    margin-top: 29px;
    margin-right: 10px;
  }

  #iconDropDown {
    margin-top: 29px;
  }

  #IconUbication {
    width: 22px;
    height: 22px;
    margin-top: 27px;
    margin-left: 15px;
  }

  #inputGroupMap {
    z-index: 1;
    justify-self: center;
    background: rgb(255, 255, 255);
    padding: 15px;
    margin: 5px;
  }

  #textRevealAcordion {
    width: 100px;
    font-size: 10px;
    margin-left: 40px;
  }

  #textRevealAcordion2 {
    width: 100px;
    font-size: 10px;
    margin-left: 30px;
  }



  /* Section 7 [Footer] */

  #cardInfoFooter {
    margin-top: 30px;
  }
}



@media (max-width: 1000px) {

  #miniLogo {
    margin-top: 5px;
    margin-left: 20px;
    width: 130px;
    height: 31px;
  }

  #sloganBox {
    margin-top: 60px;
    width: 300px;
    justify-self: center;
    margin-left: -2px;
    text-align: center;
  }

  #slogan1 {
    color: aliceblue;
    font-family: var(--fontTitle);
    font-size: 32px;
    text-align: center;
  }

  #slogan2 {
    color: aliceblue;
    font-family: var(--fontTitle2);
    line-height: 47px;
    font-size: 4ch;
    text-align: center;
  }

  #btnShowBranch {
    background: #F74550;
    color: #F8F8F8;
    position: absolute;
    top: 220px;
    right: 40px;
    height: 40px;
    border-radius: 10px;
  }

  /* Temporal Module */
  #temporalModuleInput {
    width: 300px;
    height: 49px;
    border-radius: 35px;
    max-width: 350px;
    margin-top: -20px;
  }

  #inputGroupTemporalModule {
    padding: 15px;
    margin: 5px;
    display: grid;
  }

  #headerFormServiceTemporalModule {
    color: #F8F8F8;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 20px;
    text-align: center;
    font-family: var(--fontTitle);
  }

  #helpUserTemporalModule {
    color: #F8F8F8;
    margin-top: 10px;
    margin-left: 20px;
    text-align: center;
  }

  #btnServicesTemporalModule {
    background: #F74550;
    width: 128px;
    height: 44px;
    border-radius: 35px;
    color: #F8F8F8;
    transition: 400ms;
    margin-top: 20px;
    justify-self: center;
  }

  #btnServicesTemporalModule:hover {
    transform: scale(0.97);
  }

  /*-----------------*/
  /* Section 4 [Brands and benefits] */


  #rightColumnSection4 {
    margin-top: 150px;
    width: 100%;
    justify-content: center;
    display: grid;
  }

  #howDoesItTextSection4 {
    font-family: var(--fontText1);
    font-size: 14px;
    text-align: center;
    margin-left: -40px;
  }

  #TextBottonSection4 {
    font-family: var(--fontText2);
    font-size: 14px;
    text-align: center;
  }

  #titleSection4 {
    width: 303px;
    margin-top: 10px;
    text-align: center;
    line-height: 47px;
    font-family: var(--fontTitle);
    justify-self: center;
    font-size: 41px;
  }

  #listBenefitsContainer {
    margin-top: 10px;
    width: 300px;
    height: 300px;
    justify-content: left;
  }

  #benefitsText {
    font-size: 16px;
    margin-top: 5px;
    width: 250px;
    font-family: var(--fontText1);
    font-size: 16px;
    text-align: justify;
  }

  #textSection4 {
    width: 564px;
    justify-self: center;
    font-family: var(--fontText1);
    font-size: 18px;
  }

  #iconList {
    width: 20.43px;
    height: 20.43px;
  }




}


@media (max-width: 1550px) {

  #segmentHome {
    overflow: hidden;
  }


  #headerOfert,
  #cardOfert {
    justify-self: center;
  }

  #headerOfert {
    margin-top: 20px;
    margin-left: 20px;
    font-size: 24px;
    font-family: var(--fontTitle);
  }

  #divServices {
    background: #F8F8F8;
    width: 480px;
    height: 150px;
    justify-self: center;
    border-radius: 18px;
    margin-top: 80px;
    margin-left: var(--globalMarginLeft);
  }

  #formServices {
    width: 681px;
    height: 192px;
    justify-self: center;
    margin-top: 100px;
    margin-left: var(--globalMarginLeft);
    transition: 1s;
    animation: fadeIn 2s;
  }

  /* Section 5 [Form] */

  #section5 {
    margin-top: -10px;
    margin-bottom: 40px;
    background: #490A3C;
    color: #F8F8F8;
    padding: 80px;
    display: grid;
    justify-content: center;

  }

  #leftColumnSection5 {
    justify-content: center;
    display: grid;
    width: 100%;
  }


  #rightColumnSection5 {
    position: relative;
    display: grid;
    margin-left: 15px;
    margin-top: 60px;
    padding-top: 0em;
  }



  /* Section 6 [Map]*/
  #menuSection6 {
    height: var(--menuMapDoor);
    /*90-380*/
    overflow: hidden;
    margin-bottom: 0px;
    min-width: 300px;
    transition: 1s;
    display: grid;
    justify-content: center;
  }

  #menuIconMap {

    margin-top: 15px;
    margin-left: 5px;
    padding-left: 8px;
    width: 35px;
    height: 35px;
  }

  #leftColumnMap {
    width: 100%;
    display: grid;
    justify-content: center;
  }

  #rightColumnMap {
    width: 100%;
    display: grid;
    margin-top: 60px;
    justify-content: center;
    color: #0F0F0E;
  }

  #acordionLocation {
    max-width: 100%;
    justify-self: center;
  }


  #inputServiceLargeMap {
    width: 280px;
    height: 50px;
    border-radius: 35px;
  }

  #btnServicesMap {
    background: #F74550;
    border-radius: 35px;
    margin-top: 20px;
    width: 340px;
    height: 50px;
    color: #F8F8F8;
    transition: 400ms;
    margin-left: 10px;
  }

  #inputServiceLarge {
    width: 360px;
    height: 49px;
    margin-bottom: 10px;
    border-radius: 35px;
  }

  #inputGroupMap {
    z-index: 1;
    justify-self: center;
    background: rgb(255, 255, 255);
    padding: 15px;
    margin: 5px;
    display: grid;
    justify-content: center;
  }


}


