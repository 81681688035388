body {
  background-color: #EBEEF4 !important
}

@font-face {
  font-family: "GowunBatangBold";
  src: local("GowunBatangBold"),
    url("./assets/fonts/gowunBatang/GowunBatang-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GowunBatangRegular";
  src: local("GowunBatangRegular"),
    url("./assets/fonts/gowunBatang/GowunBatang-Regular.ttf") format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "MuliRegular";
  src: local("MuliRegular"),
    url("./assets/fonts/muli/Muli-Regular.ttf") format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "MuliExtraLight";
  src: local("MuliExtraLight"),
    url("./assets/fonts/muli/Muli-ExtraLight.ttf") format("truetype");
  font-weight: lighter;
}



h1,
h2,
h3,
p,
strong,
input,
select,
textarea,
button,
label,
div {
  font-family: MuliRegular !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;