:root {
  --primary-color: #0878D7
}

*,
*::before,
*::after {
  box-sizing: border-box
}

.progressbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  margin: 4rem auto;
  width: 70%;
}

.progressbar::before,
.progress {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  width: 100%;
  background-color: #dcdcdc;
  z-index: 1
}

.progress {
  background-color: rgb(0 128 0);
  transition: 1s
}

.progress-step {
  width: 2.1875rem;
  height: 2.1875rem;
  background-color: #dcdcdc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1
}

.progress-step::before {
  counter-increment: step;
  content: counter(step)
}

.progress-step::after {
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.5rem);
  font-size: 0.85rem;
  color: #666
}

.progress-step-active {
  background-color: var(--primary-color);
  color: #f3f3f3
}

.form {
  width: 100%;
  margin: 0 auto;
  border: none;
  border-radius: 10px !important;
  overflow: hidden;
  padding: 1.5rem;
  background-color: #fff;
  padding: 20px 30px
}

.step-forms {
  display: none;
  transform-origin: top;
  animation: animate 1s
}

.step-forms-active {
  display: block
}

.group-inputs {
  margin: 1rem 0
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.progress-step-check {
  position: relative;
  background-color: green !important;
  transition: all 0.8s
}

.progress-step-check::before {
  position: absolute;
  content: '\2713';
  width: 100%;
  height: 100%;
  top: 8px;
  left: 13px;
  font-size: 12px
}

.group-inputs {
  position: relative
}

.group-inputs label {
  font-size: 13px;
  position: absolute;
  height: 19px;
  padding: 4px 7px;
  top: -14px;
  left: 10px;
  color: #a2a2a2;
  background-color: white
}

.welcome {
  height: 450px;
  width: 350px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center
}

.welcome .content {
  display: flex;
  align-items: center;
  flex-direction: column
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none
  }

  50% {
    transform: scale3d(1.1, 1.1, 1)
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142
  }
}